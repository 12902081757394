// import React from "react";

// import { Container, Row, Col, Card } from "reactstrap";

// import Slider from "react-slick";

// function Sponsor() {

//   return (
//     <>
//       <div
//         style={{
//           backgroundColor: "#fff",
//         }}
//         className="section section-with-space"
//         data-parallax={true}
//       >
//         <Container>
//           <h1
//             className="text-center text-g"
//             style={{
//               fontSize: "3rem",
//             }}
//           >
//             PAST EDITION PARTNERS
//           </h1>

//           <Row>
//             <Col lg={12} className={"mx-auto"}>
//               <Slider {...settings}>
//                 {sponsorsP.map((s, i) => (
//                   <a href={s.link} key={i} style={{ padding: "10px" }}>
//                     {/* <h4 className="text-center text-400 text-i pb-2">{s.type}</h4> */}
//                     <Card
//                       style={{ borderRadius: 0, maxWidth: "350px", boxShadow: "none" }}
//                       className="mx-auto"
//                     >
//                       <img
//                         src={require(`assets/sponsors/${s.image}`)}
//                         className="mx-auto"
//                         alt={s.name}
//                         width="100%"
//                         style={{ borderRadius: 0 }}
//                       />
//                     </Card>
//                   </a>
//                 ))}
//               </Slider>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//     </>
//   );
// }

// export default Sponsor;
// export const sponsorsP = [];

// const groupBy = (key) => (array) =>
//   array.reduce((objectsByKeyValue, obj) => {
//     const value = obj[key];
//     objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
//     return objectsByKeyValue;
//   }, {});

// const groupByType = groupBy("type");
// export const sponsors = groupByType(sponsorsP);

import { DataContext } from "DataContainer";
import React, { useContext } from "react";
import Slider from "react-slick";

import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 2,
    rows: 3,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const { pastSponsors: allSponsors } = useContext(DataContext);

  const rows = groupBy("panel")(allSponsors);
  if (allSponsors.length === 0) {
    return null;
  }
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-dark text-400"
            style={{
              fontSize: size ? "3rem" : "2rem",
            }}
          >
            PAST SPONSORS & PARTNERS
          </h1>
          <Row>
            <Col lg={12} className={"mx-auto"}>
              <Slider {...settings}>
                {allSponsors.map((s, i) => (
                  <a href={s.url} key={i} style={{ padding: "10px" }}>
                    {/* <h4 className="text-center text-400 text-i pb-2">{s.type}</h4> */}
                    <Card
                      style={{ borderRadius: 0, maxWidth: "350px", boxShadow: "none" }}
                      className="mx-auto"
                    >
                      <div
                        style={{
                          aspectRatio: "4/3",
                          display: "flex",
                          background: "white",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <img
                          src={s.image}
                          alt={s.name}
                          width="100%"
                          className="rounded-0"
                          style={{ alignSelf: "center" }}
                        />
                      </div>
                    </Card>
                  </a>
                ))}
              </Slider>
            </Col>
          </Row>
          {/* {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"} key={s.id}>
                  <a href={s.url}>
                    <h4 className="text-center text-400 text-white pb-2">{s.type}</h4>
                    <Card className="rounded-0">
                      <div
                        style={{
                          aspectRatio: "4/3",
                          display: "flex",
                          background: "white",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <img
                          src={s.image}
                          alt={s.name}
                          width="100%"
                          className="rounded-0"
                          style={{ alignSelf: "center" }}
                        />
                      </div>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))} */}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: "ESRI",
    pageLink: "ESRI",
    type: "GIS PARTNER",
    image: "esri.png",
    text: [""],
    link: "https://www.esri.com/en-us/digital-twin/overview",
    lg: 6,
  },
];
