import { DataContext } from "DataContainer";
import React, { useContext } from "react";

import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  const groupBy = (key) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = obj[key];
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});

  const { sponsors: allSponsors } = useContext(DataContext);

  const rows = groupBy("panel")(allSponsors);
  if (allSponsors.length === 0) {
    return null;
  }
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <h1
            className="text-center text-g"
            style={{
              fontSize: size ? "3rem" : "2rem",
            }}
          >
            2025 SPONSORS & PARTNERS
          </h1>
          {Object.keys(rows).map((r) => (
            <Row>
              {rows[r].map((s) => (
                <Col lg={s.size} xs={6} className={"ml-auto mr-auto pb-3"} key={s.id}>
                  <a href={s.url}>
                    <h4 className="text-center text-400 text-white pb-2">{s.type}</h4>
                    <Card className="rounded-0">
                      <div
                        style={{
                          aspectRatio: "4/3",
                          display: "flex",
                          background: "white",
                          justifyContent: "center",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        <img
                          src={s.image}
                          alt={s.name}
                          width="100%"
                          className="rounded-0"
                          style={{ alignSelf: "center" }}
                        />
                      </div>
                    </Card>
                  </a>
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
export const sponsorsP = [
  {
    name: "ESRI",
    pageLink: "ESRI",
    type: "GIS PARTNER",
    image: "esri.png",
    text: [""],
    link: "https://www.esri.com/en-us/digital-twin/overview",
    lg: 6,
  },
];
