import React from "react";

// core components
import Navbar from "../components/Navbar.js";
// import MobileNavbar from "../components/Navbars/MobileNavbar";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "../components/Footer.js";
import TextGrid from "../components/TextGrid.js";

import AboutPoints from "../components/AboutPoints.js";

import Sponsor from "../components/Sponsor.js";
// import ImageGrid from "../components/ImageGrid.js";
// import PastEventHighlights from "../components/PastEventHighlights";
// import PastEventHighlights1 from "../components/PastEventHighlights1";
// import Workshop from "../components/Workshop.js";
import Speaker from "../components/SpeakerCard";
import SponsorNew from "../components/SponsorNew";

import Attend from "components/Attend.js";
import Editions from "components/Editions.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <Navbar />
      <LandingPageHeader />
      <section id="about">
        <TextGrid />
      </section>
      <div
        style={{
          background: `url(${require(`assets/images/black-smooth-textured-paper.jpg`)})`,
          // backgroudColor: "#020910",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundAttachment: size ? "fixed" : "scroll",
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <section id="attend">
          <Attend />
        </section>
        <AboutPoints />
        <section id="speakers" />
        <Speaker />
        {/* <section id="workshop" />
        <Workshop /> */}
        <section id="partners" />
        <SponsorNew />
        <Editions />
      </div>

      {/* {size ? <PastEventHighlights1 /> : <PastEventHighlights />} */}

      {/* <Sponsor /> */}
      <DemoFooter />
    </>
  );
}

export default Home;
