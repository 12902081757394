import React from "react";
import { Container, Row, Col } from "reactstrap";
import Card from "reactstrap/lib/Card";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          background: `url(${require("assets/images/photo-1566734904496-9309bb1798ae.jpeg")})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundSize: "cover",
          paddingTop: "8rem",
          overflow: "hidden",
        }}
        className="section py-3"
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="overlay-primary"></div>
        <Container>
          <Row className="justify-content-center">
            <Col md={6} className={size ? "border-right" : "border-bottom"}>
              <h1
                className="m-0 my-4 text-g text-white"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Event
                <br />
                Summary
              </h1>
              <p className="text-400 text-white">
                <span className="text-700 text-i">The Brisbane 2032 Olympics </span> is not just an
                opportunity to be on the sports world map, but an opportunity to position Queensland
                and Australia as a leader in digitization in infrastructure design to delivery. This
                includes implementing Digital Twins for building a major public transit project in
                Brisbane and other key venues. Working towards the Olympics 2032 will need to ensure
                early embedding of the digital twin ecosystem that is crucial for a successful
                output by creating a data-driven and collaborative culture that is well beyond the
                Games themselves.
                <br />
                <br />
                With the success of the{" "}
                <span className="text-700 text-i">NZ DIGITAL TWIN 2024</span> conference in
                Auckland, <span className="text-700 text-i">Saifee Events </span>is stoked to
                continue the dialogue in Brisbane, Australia at the 2nd Annual ANZ DIGITAL TWIN 2025
                conference & exhibition on May 29 and Workshops on May 30.
                <br />
                <br />
                The conference will explore the roadmap of a Digital Twin system, its impact on each
                stage of the project and explore the core values, challenges and opportunities
                unique to the priorities in Australia and how Digital Twins can deliver them; across
                the ANZ region.
                <br />
                <br />
                Hear from technical experts, industry leaders, researchers and everything in between
                and join us for a stand out experience designed to support the development of a
                dynamic and scalable digital twin ecosystem in Australia and New Zealand.
                <br />
                <br />
              </p>
            </Col>
            <Col md={6} className="pr-0">
              <h1
                className="m-0 my-4 text-g"
                style={{
                  fontSize: size ? "3rem" : "2rem",
                }}
              >
                Conference
                <br />
                Format
              </h1>

              <div className="container-fluid row justify-content-center">
                {content.map((c, i) => (
                  <Col xs={12}>
                    <Card
                      style={{
                        background: c.color,
                        minHeight: size ? "80px" : "none",
                        maxWidth: "400px",
                      }}
                    >
                      <div className="d-flex p-2  ">
                        <div className="px-2 align-self-center" style={{ maxWidth: "70px" }}>
                          <img
                            src={require(`assets/icons/${c.image}`)}
                            // style={{ width: "70px" }}
                            width="100%"
                            alt="about"
                            className=""
                          />
                        </div>
                        <p className="text-400 py-2 pl-3 text-white align-self-center">{c.title}</p>
                      </div>
                    </Card>
                  </Col>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "VIP KEYNOTE SPEECHES",
    image: "14.png",
    color: "#7fc1bc",
  },
  {
    title: "PROJECT CASE STUDIES AND PROOF OF CONCEPTS",
    image: "12.png",
    color: "#b5b84a",
  },
  {
    title: "THOUGHT-PROVOKING PANEL DISCUSSIONS",
    image: "42.png",
    color: "#7fc1bc",
  },
  {
    title: "ROUND TABLE SESSIONS",
    image: "Asset 7.png",
    color: "#b5b84a",
  },
  {
    title: "TECHNICAL WORKSHOPS",
    image: "41.png",
    color: "#7fc1bc",
  },
  {
    title: "EXHIBITION & NETWORKING ",
    image: "43.png",
    color: "#b5b84a",
  },
];
