import React from "react";

// core components
import Navbar from "../components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "../components/Footer.js";
import PastEventHighlights1 from "../components/PastEventHighlights1";
import Photos from "../components/Photos";
import Sponsor from "components/Sponsor.js";
import SpeakerGrid from "components/PastSpeakerCard.js";

function Home() {
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="PAST EVENT  HIGHLIGHTS" bgColor="#fff" />
      <div data-parallax={true}>
        <PastEventHighlights1 />
        <SpeakerGrid />
        <Sponsor />
        <Photos />
      </div>
      <DemoFooter />
    </>
  );
}

export default Home;
