import React, { useContext } from "react";

import { Container, Row, Col, Card, Modal, Button } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import Slider from "react-slick";

import { DataContext } from "DataContainer";

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 4,
    slidesToScroll: 1,
    rows: 1,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const { pastSpeakers } = useContext(DataContext);

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={speaker.image}
                  alt="speaker"
                  width="100%"
                  className="rounded-circle p-2"
                  style={{ border: "2px #5ab030 dashed" }}
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center text-dark">
                  <h4 className="text-700 mt-2 mb-0 text-g d-block px-0">
                    {speaker.highlightText}
                  </h4>
                  <p className="text-700 mt-2 mb-0 d-block px-0 ">{speaker.name}</p>
                  <p className="text-300 m-0">{speaker.title}</p>
                  <p
                    className="text-400 m-0 d-block px-0"
                    // style={{ fontSize: "16px" }}
                  >
                    {speaker.company}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          background: "#fff",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="filter" />
        <Row>
          <Col lg={12} className="">
            <h1
              className="text-center text-dark text-400"
              style={{
                fontSize: size ? "3rem" : "2rem",
              }}
            >
              PAST SPEAKERS
            </h1>
          </Col>
        </Row>
        <Container>
          <Row className="py-4">
            {size ? (
              pastSpeakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={3} xs={12} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {pastSpeakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>

          <Row className="justify-content-center">
            <Col lg={3} className="text-center px-1">
              <Button
                href="/register"
                className="rounded-0 text-400 text-dark"
                color="primary"
                size="lg"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  REGISTER NOW
                </span>
              </Button>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="section profile-content">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#888888",
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;

export const speakers = [];
